body {
  margin: 0px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  overflow-x: hidden !important;
  
}

.root{
  overflow: hidden;
}
@media (max-width: 320px) {
  body {
    margin: 0px !important;
    
    overflow-x: hidden !important;
 
  }

}

@media (max-width: 425px) {
  body {
    margin: 0px !important;
    
    overflow-x: hidden !important;
    background: blue;
  }

}
@media (max-width: 700px) {
  body {
    margin: 0px !important;

    background: blue;
    overflow-x: hidden !important;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiSlider-root{
  padding: 13px 0 !important;
 
}
