.simulador {
  font-family: eliotpro;
}

.proyecciones {
  margin: 10px;
}
.cantidad {
  color: #616267;
  font-size: 28px;
  font-weight: 400;
}

.cantidad-mob {
  color: #616267;
  font-size: 28px;
  font-weight: 600;
}

.color-first,
.item-label-first {
  margin-bottom: 6px !important;
}

.chartImg {
  width: 85%;
}

.percentages {
  justify-content: center;
  display: flex;
}
.perfil {
  padding: 30px 0;
}
.btext {
  font-size: 16px;
  font-weight: 700;
}

.pregunta {
  font-size: 16px;
  font-weight: 700;
}

.col1 {
  align-self: flex-end;
  transform: translate(5px, -30px);
}

.col2 {
  align-self: flex-start;
  transform: translate(-80px, 20px);
}

.des {
  font-size: 16px;
  font-weight: 400;
  color: #616267;
}

.parrafo {
  font-size: 14px;
  font-weight: 400;
}

.cartera {
  width: 100%;
  font-size: 14px;
  border-radius: 10px;
  background-color: #f0fbfe;
  padding: 5px 0;
}
.item-label {
  background-color: #f0fbfe;
}

a {
  color: #0061a0 !important;
  font-size: 14px;
  text-decoration-style: dotted !important;
}
@media (max-width: 1000px) {
  .col1 {
    align-self: flex-end;
    transform: translate(45%, -10px);
  }
}
@media (max-width: 900px) {
  .accordion {
    width: 90%;
  }
}

@media (max-width: 800px) {
  .col1 {
    transform: translate(45%, -10px);
  }
  .col2 {
    transform: translate(-90px, 20px);
  }
}
@media (max-width: 550px) {
  .cantidad {
    font-size: 18px;
    font-weight: 700;
    color: #616267;
  }
  .des {
    font-size: 14px;
    font-weight: 700;
    color: #616267;
  }
}

@media (max-width: 450px) {
  .col2 {
    transform: translate(-70%, 20px);
  }
}
