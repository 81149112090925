.mainSection {
  background: linear-gradient(rgba(0, 40, 85, 0.75), rgba(0, 40, 85, 0.75)),
    url("./../img/MainImage.jpg") no-repeat center center fixed;
  background-size: cover;
}
.item-wrapper {
  width: 50%;
  justify-content: flex-end;
  display: flex;
}
.question-wrapper {
  width: 100%;
  max-width: 710px;
  padding: 0 50px;
}

.ContainerRadioInput {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.radio-item {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}

.radio-item input[type="checkbox"] {
  display: none;
}

.radio-item label {
  color: black;
  font-weight: normal;
  display: flex;
}

.radio-item label > b {
  margin-top: 5px;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 0px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 1px solid black;
  background-color: transparent;
}

.radio-item input[type="checkbox"]:checked + label:after {
  border-radius: 11px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;

  content: " ";
  display: block;
  border: 0px;
  background: #00efe8;
}

.radio-liq {
  align-items: center;
  align-content: center;
  display: flex;
}

.radio-liq input[type="radio"] {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  accent-color: #0061a0;
}
.radio-liq label {
  transform: translateY(2px);
}

.radio-perfilador {
  width: 100%;
  align-items: flex-start;
  display: flex;
}

.radio-perfilador-safari {
  width: 100%;
  align-items: flex-start;
  z-index: 0;
  display: flex;
}

.radio-perfilador input[type="radio"] {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  accent-color: #0061a0;
}
.radio-perfilador label {
  transform: translateY(2px);
}

.radio-input {
  width: 4%;
  height: 20px;
  min-width: 20px !important;
}
.radio-input-safari {
  width: 4%;
  height: 20px;
  min-width: 20px !important;
  position: absolute;
  z-index: 1;
}

.input-radio {
  transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -webkit-appearance: radio;
  -webkit-font-smoothing: antialiased;
  resize: none;
}

.selectedStep.accomplished {
  background-color: #00efe8 !important;
  border: 1px solid #00efe8 !important;
}

.step-progress-bar {
  height: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.circle-full {
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #616267;
}
.circle-empty {
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: solid 1px #616267;
  background: #ffffff;
}
.line {
  width: 27px;
  border-top: solid 2px #616267;
  transform: translateY(9px);
}

.line-2 {
  width: 35px;
  border-top: solid 2px #616267;
  transform: translateY(15px);
}

.hide {
  display: none;
}

.mainSectionPerfilador {
  display: flex;
  align-items: center;
  background: linear-gradient(rgba(0, 40, 85, 0.75), rgba(0, 40, 85, 0.75)),
    url("./../img/MainImage.jpg");
  /*background: linear-gradient(rgba(0,40,85,.75),rgba(0,40,85,.75)),
    url(https://blog-principal-mx.pantheonsite.io/rocket/static/media/MainImage.0daaf10….jpg);*/
  background-position: top -300px right 0px;
  background-size: cover;
}

@media (max-width: 800px) {
  .mainSection {
    background: linear-gradient(rgba(0, 40, 85, 0.4), rgba(0, 40, 85, 0.4)),
      url("./../img/MainImage.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

@media (max-width: 900px) {
  .mainSectionPerfilador {
    background: linear-gradient(rgba(0, 40, 85, 0.75), rgba(0, 40, 85, 0.75)),
      url("./../img/MainImage.jpg") center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width: 700px) {
  .mainSectionPerfilador {
    background: linear-gradient(rgba(0, 40, 85, 0.4), rgba(0, 40, 85, 0.4)),
      url("./../img/MainImage.jpg");
    background-repeat: no-repeat;
    /*background-position: top -2px right -272px;
    background-size: 1045px 753px;*/
    background-position: center center;
    background-size: cover;
  }
}

@media (max-width: 600px) {
  .question-wrapper {
    width: 100%;
    padding: 0 20px;
  }

  .radio-perfilador {
    width: 100%;
    align-items: flex-start;
    display: flex;
    position: relative;
    z-index: 0;
  }

  .radio-input {
    width: 4%;
    height: 20px;
    min-width: 20px !important;
    z-index: 1;
    position: absolute;
  }

  .radio-input-safari {
    width: 4%;
    height: 20px;
    margin-bottom: 10px;
    min-width: 20px !important;
    position: absolute;
    z-index: 1;
  }
}
